html, body {margin: 0; height: 100%; overflow: hidden}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.ipod {
  width: 320px;
  height: 525px;
  margin: auto;
  border: rgb(235, 235, 235) 2px solid;
  margin-top: 50px;
  border-radius: 25px;
  font-family: 'PT Sans', sans-serif;

  @media only screen and (max-width: 1281px) {
    // display: none;
  }
}

.mobile {
  position: fixed;
  z-index: 2;
  background-color: rgba(0,0,0,0.75);
  top: 0;
  left: 0;
  width: 100%;
  margin: auto;
  color: white;
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: white;
  }
  @media only screen and (min-width: 990px) {
    display: none;
  }
}

.bezel {
  height: 40%;
  padding: 20px 15px;
}

.screen {
  width: 95%;
  margin: auto;
  border: black 3px solid;
  height: 100%;
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 1;
  }
}

.status-bar {
  top: 0;
  width: 100%;
  z-index: 1;
  text-align: center;
  padding: 5px 2px;
  background: rgb(141,164,178);
  background: linear-gradient(0deg, rgba(141,164,178,0.6839110644257703) 0%, rgba(206,221,243,1) 51%, rgba(211,226,248,1) 100%);

  &__title {
    font-weight: 900;
    font-size: 16px;
    word-wrap: none;
    width: 100%;
  }

  .battery {
    position: absolute;
    right: 2px;
    text-align: right;
    padding-right: 2px;
  }
  .status {
    position: absolute;
    left: 2px;
    text-align: left;
    width: 50%;
    width: 15px;
    height: 15px;
    .arrow-right {
      position: absolute;
      width: 0;
      height: 0;
      left: 2px;
      border-top: 8px solid transparent;
      border-left: 16px solid rgb(3, 110, 233);
      border-bottom: 8px solid transparent;
    }
  }
}
.controls {
  display: flex;
}

.menu-item {
  display: block;
  font-weight: 700;
  text-decoration: none;
  color: black;
  padding: 4px 5px;

  &::after {
    content: '>';
    position: absolute;
    right: 5px;
  }

  &.active {
    background: rgb(3, 110, 233);
    color: white;
  }
}

.clicker-buttons {
  margin: 30px;
  height: 200px;
  width: 200px;
  overflow: hidden;
  border-radius: 50%;
  background: rgb(228, 227, 227)
}

svg {
  height: 100%;
  width: 100%;
}
polygon {
  fill: transparent;
  stroke: none;
  stroke-linejoin: round;
}
polygon:hover {
  fill: cornflowerblue;
}

.wheel {
  position: relative;
  cursor: pointer;
  margin: auto;
}

.clicker {
  position: absolute;
  width: 60px;
  height: 60px;
  background: white;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  &:hover {
    background: cornflowerblue;
  }
}

.page {
  position: absolute;
  width: 100%;
  height: 85%;
  overflow: scroll;
  overflow-x: hidden;
  &.no-scroll {
    overflow: hidden;
  }

  .page-container {
    padding: 5px;
  }

  h4 {
    font-weight: bold;
  }
}

.fade-exit {
  right: 100%;
  &-active {
    animation: exit .5s ease-in-out;
  }
}

.fade-enter {
  right: 0;
  &-active {
    animation: enter .5s ease-in-out;
  }
  &-done {
    animation: enter .5s ease-in-out;
  }
}

@keyframes exit {
  0% { right: 0; display: block;}
  100% { right: 100%; display: none;}
}
@keyframes enter {
  0% { right: -100%; }
  100% { right: 0; }
}

.back-fade-exit {
  right: 100%;
  &-active {
    animation: back-exit .5s ease-in-out;
  }
}

.back-fade-enter {
  right: 0;
  &-active {
    animation: back-enter .5s ease-in-out;
  }
  &-done {
    animation: back-enter .5s ease-in-out;
  }
}

@keyframes back-exit {
  0% { right: 0; }
  100% { right: -100%; }
}
@keyframes back-enter {
  0% { right: 100%;}
  100% { right: 0; }
}

.battery {
  img {
    width: 25px;
    padding-right: 2px;
  }
}

.square {
  height: 200px;
  background: pink;
  transform: rotate(45deg);
  cursor: pointer;

  .row {
    height: 100px;
    display: flex;

    & > div {
      height: 100px;
      width: 100px;
      background: rgb(209, 209, 209);
      position: relative;

      & > span {
        position: absolute;
        top: 30%;
        left: 25%;
        transform: rotate(-45deg);
        text-transform: uppercase;
        font-weight: bold;
        color: white;
        font-size: 14px;

        & > img {
          width: 15px;
          height: 10px;
          position: absolute;
        }

        &.prev {
          top: 60%;
          left: 32%;
        }
        &.next {
          top: 38%;
          left: 49%;
        }
        &.play {
          top: 62%;
          left: 50%;
        }
      }

      &:hover {
        background: rgb(233, 233, 233);
      }
    }
  }
}

.skill-group {
  padding-top: 5px;
}

.skills {
  padding-top: 25px;
  padding-left: 5px;
}

.now-playing {
  display: none;
  &.playing {
    display: block;
  }
}

.artwork {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80%;

  .artwork-image {
    width: (100%/3);
    padding-left: 5px;
    & > img {
      width: 80%;
    }
  }
  .meta {
    width: (100%/3) * 2;

    & > p {
      font-weight: bold;
      font-size: 1em;
      padding-bottom: 5px;
    }
  }
}

.progress-bar {
  height: 15px;
  width: 94%;background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 34%, rgba(190,190,190,1) 47%, rgba(255,255,255,1) 57%);
  margin: auto;
  border: rgb(197, 197, 197) 1px solid;
  overflow: hidden;
  position: relative;
  
  .progress {
    height: 100%;
    width: 0;
    position: absolute;
    background: rgba(3, 110, 233, 0.85);
  }
}

h3 {
  font-weight: bold;
}

.smaller-p {
  font-size: .8em;
}

.instructions {
  padding-top: 25px;
  top: 0;
  left: 0;
  z-index: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & span {
    font-weight: bold;
  }
  & > p {
    padding-bottom: 10px;
  }

  .close-button {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
  }
}